

.lotteryContainer {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    margin-top:0px;
    margin-bottom:15px;
    gap:10px;
}


.lotteryCard {

    width:400px;
    
    
}

.lotteryCard .ant-card-body {
    background-color: rgba(255, 255, 255, 0.9);
    padding:10px;
    text-align: center;
}

.subscribebutton {
    background-color: white;
    border: 1px solid black;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: auto;
    cursor: pointer;
    border-radius: 10px;
    
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}