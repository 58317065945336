.summary{
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 10px;
    display: flex;
  }

  .header-bold .ant-table-cell {
    font-weight: bold;
}


.selectdescription {
  white-space: normal;
  word-wrap: break-word;
  width: 100%;
}

.ant-select-item-option-content{
  width:max-content;
  min-width: 100%;
}