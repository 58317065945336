.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.registration-form {
  width: 300px;
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 5px;
}

.login-form {
  width: 300px;
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 5px;
}