.navigation {
  display: flex;
  justify-content: space-between;
  
}

.menu-left, .menu-right {
  flex: 1;
  margin-bottom: -10px;
  display: flex;
  justify-content: flex-start;
  padding-top: 5px; /* Align items to the start in the left menu */
}

.menu-right {
  justify-content: flex-end; /* Align items to the end in the right menu */
}

.ant-menu-item{
  cursor: pointer;
  border: none !important;
}

.ant-menu-item-active::after{
  border: none !important;
}

.ant-menu-item-active::before{
  border: none !important;
}

.ant-menu-item-active:hover{
  border: none !important;
}

.ant-menu-item-selected::after {
  border: none !important;
}

.ant-menu-item-selected::before {
  border: none !important;
}

.ant-menu-item:hover::before{
    border: none !important;
  } 

.ant-menu-item:hover::after{
    border: none !important;
  }

.label-container {
  display: flex;
  justify-content: center;
  align-items: end;

  & > label {
    padding-right: 10px;
    font-size: 9pt;
    
  }
  
}


.selected-cell {
    background-color: green;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 8pt;
    max-height: 8px;
}

.cell {
    max-width: 30px;
    max-height: 8px;
    overflow: hidden;
    padding:5px !important;
    font-size: 8pt !important;

}

.cellFirstRow {
  max-width: 50px;
    max-height: 10px;
    overflow: hidden;
    padding:8px !important;
   
}

.ant-select-item-option-content {
  text-overflow: clip !important;
}