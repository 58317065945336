
.simulate-section {
    flex: 3;
    padding-left: 5px;
    overflow: auto;
    transition: transform 2.5s ease-in-out;
    transform: translateX(100%);
    border-left: 1px solid #dadada; /* Add a border to the left */
    box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.5); /* Add a shadow on the left */
  }
  
  .simulate-section.visible {
    transform: translateX(0);
  }

  .compact-table .ant-table-row {
     /* Adjust this value to your preference */
    max-width: 10px !important; 
  min-width: 10px !important; 
  max-height: 20px !important; 
  min-height: 20px !important; 
  }


  .ant-table-placeholder {
  display: none;
}

  .no-borders  td {
    border: none !important;
  }
  .title {
    font-size: 1em;
    margin: 1;
    padding: 0;

  }
  .ant-steps-item  .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title{

    color:black !important
  }
  .ant-tooltip .customTooltip {
    margin-top: 13px !important;
  }

  .switchFilter{
    background-color: red !important;
  }

 .ant-switch-checked{
    background-color: green !important;
  }


  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;

    color: blue;
    border: none;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
 
  }

  .ant-select-selector .deleteButton {
    display: none;
  }

  .ant-select-selector .selectdescription {
    display: none;
  }

  .ant-radio-group {
    height: 32px;
  }

  .filters {
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 1%;
    display: flex;
  }

  .filters__option{
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 10px;
    display: flex;
  }
 
  .filters__option label {
    margin-bottom: 1px;
    font-size: 10pt;
  }

  .LivePreview {
    margin: 0;
    position: absolute;
    left: 0;
    transform: none !important;
  }

  .LivePreview:not(*) .ant-modal-mask {
    background-color: transparent !important;
    position: inherit;
  }