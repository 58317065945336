.normal-column, .light-column {
  
  padding:0px !important;
  margin:2px !important;
  max-width: 10px !important; 
  min-width: 10px !important; 
  max-height: 10px !important; 
  min-height: 10px !important; 
  text-align: center !important;
  vertical-align: middle !important; 
  line-height: 9pt !important; 
}

.ant-table tbody tr td {
  border-bottom: 1px solid #b4b4b4 !important;
  border-right: 1px solid #b4b4b4 !important;
}

.ant-table tbody tr td:last-child {
  border-right: none !important;
}

.ant-table tbody tr:last-child td {
  border-bottom: none !important;
}


.first-column{
    text-align: right !important;
}
.ant-table-row tr :hover {
  background-color: lightgrey;
}



.compact-table .ant-table-row {
  line-height: 0.4; /* Adjust this value to your preference */
}

.ant-table-thead {
  font-size: 9px; /* Adjust this value as needed */
}

.ant-table-tbody {
  font-size: 9px; /* Adjust this value as needed */
}

.blue-highlight {
  background-color: #e6f7ff !important;
}

.yellow-highlight {
  background-color: #ffffb3 !important;
}

.ant-steps-horizontal .stepsDraws {
  display: inline !important;

}

.form-item-label-above  .ant-form-item-row {
display: block !important;
}

.patternBox {

  border-style: dashed;
  border-width: 1px;
  border-color: #373737;
  padding-left: 10px;
  padding-right: 10px;
}